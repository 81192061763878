import axios from 'axios';
import API from './API';
import { loginActions } from './../store/login-slice';

const setupInterceptors = (store) => {
  API.interceptors.request.use(
    config => {
      config.headers['Authorization'] = `Bearer ${localStorage.getItem('access_token')}`;
      return config;
    },
    error => {
      return Promise.reject(error);
    }
  );

  API.interceptors.response.use(
    response => {
      return response;
    },
    async error => {
      const originalRequest = error.config;

      // Check if it's a 401 error and the original request hasn't been retried yet
      if (error.response.status === 401 && !originalRequest._retry) {
        originalRequest._retry = true;

        const refresh_token = localStorage.getItem('refresh_token');
        if (refresh_token) {
          try {
            // Get necessary details from localStorage
            const COGNITO_USER_DOMAIN = localStorage.getItem('COGNITO_USER_DOMAIN');
            const COGNITO_USER_APP_CLIENT_ID = localStorage.getItem('COGNITO_USER_APP_CLIENT_ID');

            // Prepare the request body for refreshing the token
            const requestBody = `grant_type=refresh_token&client_id=${COGNITO_USER_APP_CLIENT_ID}&refresh_token=${refresh_token}`;

            // Call the token refresh endpoint
            const res = await axios.post(`${COGNITO_USER_DOMAIN}/oauth2/token`, requestBody, {
              headers: {
                'Content-Type': 'application/x-www-form-urlencoded'
              }
            });

            if (res.status === 200) {
              // Store the new access token
              localStorage.setItem('access_token', res.data.access_token);

              // Update the Authorization header
              API.defaults.headers.common['Authorization'] = `Bearer ${res.data.access_token}`;
              originalRequest.headers['Authorization'] = `Bearer ${res.data.access_token}`;

              // Retry the original request
              return API(originalRequest);
            }
          } catch (err) {
            // If refreshing the token fails, log the user out
            localStorage.removeItem('access_token');
            localStorage.removeItem('refresh_token');
            localStorage.removeItem('COGNITO_USER_DOMAIN');
            localStorage.removeItem('COGNITO_USER_APP_CLIENT_ID');
            store.dispatch(loginActions.setLogout());
            return Promise.reject(error);
          }
        } else {
          // If no refresh token is available, log the user out
          localStorage.removeItem('access_token');
          store.dispatch(loginActions.setLogout());
          return Promise.reject(error);
        }
      }

      // If the error is not due to token expiration, reject the promise
      return Promise.reject(error);
    }
  );
};

export default setupInterceptors;
