import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import './App.scss';
import AppRoutes from './AppRoutes';
import Navbar from './shared/Navbar';
import Sidebar from './shared/Sidebar';
import SettingsPanel from './shared/SettingsPanel';
import Footer from './shared/Footer';
import HeaderTitle from './shared/HeaderTitle';

class App extends Component {
  state = {}
  componentDidMount() {
    this.onRouteChanged();
  }
  render() {
    let navbarComponent = !this.state.isFullPageLayout ? <Navbar /> : '';
    let sidebarComponent = !this.state.isFullPageLayout ? <Sidebar /> : '';
    let titleComponent = !this.state.isFullPageLayout ? <HeaderTitle /> : '';
    let SettingsPanelComponent = !this.state.isFullPageLayout ? <SettingsPanel /> : '';
    let footerComponent = !this.state.isFullPageLayout ? <Footer /> : '';
    return (
      <div className="container-scroller">
        {navbarComponent}
        <div className="container-fluid page-body-wrapper">
          {sidebarComponent}
          <div className="main-panel">
            <div className="content-wrapper">
              {titleComponent}
              <AppRoutes />
              {/* { SettingsPanelComponent } */}
            </div>
            {/* { footerComponent } */}
          </div>
        </div>
      </div>
    );
  }

  componentDidUpdate(prevProps) {
    if (this.props.location !== prevProps.location) {
      this.onRouteChanged();
    }
  }

  onRouteChanged() {
    window.scrollTo(0, 0);
    const fullPageLayoutRoutes = ['/login', '/download-pdf/', '/user-pages/login-1', '/user-pages/login-2', '/user-pages/register-1', '/user-pages/register-2', '/user-pages/lockscreen', '/error-pages/error-404', '/error-pages/error-500'];

    const isFullPageLayout = fullPageLayoutRoutes.some(path =>
      this.props.location.pathname.startsWith(path)
    );

    this.setState({
      isFullPageLayout: isFullPageLayout
    });

    const pageBodyWrapper = document.querySelector('.page-body-wrapper');
    if (isFullPageLayout) {
      pageBodyWrapper.classList.add('full-page-wrapper');
    } else {
      pageBodyWrapper.classList.remove('full-page-wrapper');
    }
  }


}

export default withRouter(App);
