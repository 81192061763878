import React from 'react'
import { useSelector } from 'react-redux'
function HeaderTitle() {
    const user = useSelector((state) => state.login.user)
    console.log(user)
  return (
    <h2 className='d-flex align-items-center'><span className="text-black fw-bold  ">Welcome {user.firstName}!</span> <span className='useremail'>{'<'+user.email+'>'}</span></h2>
  )
}

export default HeaderTitle