import { createSlice } from "@reduxjs/toolkit";

const serviceSlice = createSlice({
    name: 'service',
    initialState: { loading: false, service: []},
    reducers: {
      
        setService(state, action) {
            state.service= action.payload;
        }

    },
});

export const serviceActions = serviceSlice.actions;

export default serviceSlice;
